import axios from "axios";
import { BASE_URL } from "../../constants";
import { actions as cardsActions } from "../reducers/cards";
import { get } from "lodash";
import { notification } from "antd";
import { NotificationManager } from "react-notifications";
export const GET_LISTS_START = "GET_LISTS_START";
export const GET_LISTS = "GET_LISTS";
export const MOVE_CARD = "MOVE_CARD";
export const MOVE_LIST = "MOVE_LIST";
export const TOGGLE_DRAGGING = "TOGGLE_DRAGGING";
let lists = [];

export function setBoard(board) {
  return (dispatch) => {
    dispatch({ type: "SET_ACTIVE_BOARD", board });
  };
}

const requestAPI = (reqUrl, reqParams = {}) => {
  const reqObj = {
    url: BASE_URL + reqUrl,
    headers: { Authorization: localStorage.getItem("authorization") },
  };

  if (reqParams.method === "get") {
    Object.assign(reqObj, {
      method: "GET",
      params: reqParams.data || {},
    });
  } else if (reqParams.method === "put") {
    Object.assign(reqObj, {
      method: "PUT",
      data: reqParams.data || {},
    });
  } else {
    Object.assign(reqObj, {
      method: "POST",
      data: reqParams.data || {},
    });
  }
  return axios(reqObj)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export function addColumn(board) {
  return (dispatch) => {
    dispatch({ type: "ADD_COLUMN_START" });
    return axios({
      method: "post",
      url: BASE_URL + "column",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        name: "",
        board: board._id,
        position: board.columns.length,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "ADD_COLUMN_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "ADD_COLUMN_FAIL", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "ADD_COLUMN_FAIL" });
        if (error.response.status === 401) {
          dispatch({ type: "HIDE_ADD_BOARD" });
        }
      });
  };
}
export function addCard(board, column, position, email = "", labels = []) {
  let labelAssigned = labels.map((lbl) => lbl._id);
  labelAssigned = labelAssigned.join(",");
  return (dispatch) => {
    dispatch({ type: "ADD_CARD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "card",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        title: "",
        board: board._id,
        column: column._id,
        position: position,
        labelAssigned,
        createdBy: email.toLowerCase(),
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "ADD_CARD_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "ADD_CARD_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_CARD_FAIL" });
      });
  };
}
export function addDuplicateCard(card) {
  return (dispatch) => {
    card.position = 0;
    delete card["_id"];
    return axios({
      method: "post",
      url: BASE_URL + "card",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: card,
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "ADD_CARD_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "ADD_CARD_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_CARD_FAIL" });
      });
  };
}
export function addTask(title, note, date, type, assignTo, card) {
  return (dispatch) => {
    dispatch({ type: "ADD_TASK_START" });
    return axios({
      method: "post",
      url: BASE_URL + "task",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        title,
        card,
        note,
        date,
        type,
        assignTo,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "ADD_TASK_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "ADD_TASK_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_TASK_FAIL" });
      });
  };
}
export function addCardComment(data) {
  return (dispatch) => {
    dispatch({ type: "ADD_COMMENT_START" });
    return axios({
      method: "post",
      url: BASE_URL + "comment",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: data,
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "ADD_COMMENT_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "ADD_COMMENT_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_COMMENT_FAIL" });
      });
  };
}

export function addCardAttachment(data, isUpdate = false) {
  return (dispatch) => {
    let apiUrl = "card/add-card-attachment";
    if (isUpdate) {
      apiUrl = "card/update-card-attachment";
    }
    dispatch({ type: "ADD_CARD_ATTACHMENT_START" });
    return axios({
      method: "post",
      url: BASE_URL + apiUrl,
      headers: { Authorization: localStorage.getItem("authorization") },
      data: data,
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "ADD_CARD_ATTACHMENT_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
          document.getElementById(`handleImageUpload-${data.card}`).reset();
        } else {
          dispatch({
            type: "ADD_CARD_ATTACHMENT_FAIL",
            payload: response.data,
          });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_CARD_ATTACHMENT_FAIL" });
      });
  };
}

export function updateCardNote(comment, id, date, card) {
  return (dispatch) => {
    dispatch({ type: "EDIT_NOTE_START" });
    return axios({
      method: "put",
      url: BASE_URL + "comment",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        comment,
        id,
        date,
        card,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "EDIT_NOTE_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "EDIT_NOTE_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "EDIT_NOTE_FAIL" });
      });
  };
}

export function deleteCardNote(id, card) {
  return (dispatch) => {
    dispatch({ type: "DELETE_NOTE_START" });
    return axios({
      method: "delete",
      url: BASE_URL + "comment",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        id,
        card,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "DELETE_NOTE_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "DELETE_NOTE_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "EDIT_NOTE_FAIL" });
      });
  };
}
export function completeTask(task) {
  return (dispatch) => {
    dispatch({ type: "COMPLETE_TASK_START" });
    return axios({
      method: "post",
      url: BASE_URL + "task/complete",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        task,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "COMPLETE_TASK_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "COMPLETE_TASK_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "COMPLETE_TASK_FAIL" });
      });
  };
}

export function delayTask(task) {
  return (dispatch) => {
    dispatch({ type: "DELAY_TASK_START" });
    return axios({
      method: "post",
      url: BASE_URL + "task/later",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        task,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "DELAY_TASK_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "DELAY_TASK_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "COMPLETE_TASK_FAIL" });
      });
  };
}

export function createBoardFromCard(card) {
  return axios({
    method: "post",
    url: BASE_URL + "card/create-board",
    headers: { Authorization: localStorage.getItem("authorization") },
    data: {
      card,
    },
  });
}

export function moveList(lists) {
  return (dispatch) => {
    dispatch({ type: MOVE_LIST, lists });
    return axios({
      method: "put",
      url: BASE_URL + "column/position",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        columns: lists
          .map((item, index) => {
            const obj = {};
            obj.position = index + 1;
            obj.id = item._id;
            return obj;
          })
          .sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
          }),
      },
    })
      .then(() => {
        //  dispatch({ type: "SET_STATE", payload: { reloadList: true } });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({ type: "HIDE_ADD_BOARD" });
        }
      });
  };
}

export function moveCard(lists, board) {
  return (dispatch) => {
    const listCopy = Object.assign([], lists);
    const cards = listCopy
      .map((item) => {
        item.cards.map((card, index) => {
          const obj = card;
          obj.column = item._id;
          obj.position = index;
          return obj;
        });
        return item;
      })
      .reduce((a, b) => {
        Array.prototype.push.apply(a, b.cards);
        return a;
      }, []);
    const columns = lists.map((item) => {
      const obj = item;
      obj.cards = obj.cards.map((card) => {
        return card._id;
      });
      return obj;
    });
    lists.forEach((list) => {
      list.cards = list.cards.map((listCard) => {
        return cards.find((card) => {
          if (card._id == listCard) {
            return card;
          }
        });
      });
    });
    board.columns = lists;
    dispatch({ type: MOVE_CARD, board: board, lists });
    return axios({
      method: "put",
      url: BASE_URL + "card/position",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        columns,
        cards,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({ type: MOVE_CARD, board: response.data.data, lists });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch({ type: "HIDE_ADD_BOARD" });
        }
      });
  };
}

export function toggleDragging(isDragging) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_DRAGGING, isDragging });
  };
}

export function createNewBoard(boardName, boardTypeId) {
  return (dispatch) => {
    dispatch({ type: "CREATE_BOARD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "board",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        name: boardName,
        boardType: boardTypeId,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "CREATE_BOARD_SUCCESS", payload: response.data });
          notification.success({ message: "Your board created successfuly." });
        } else {
          dispatch({ type: "CREATE_BOARD_FAIL", payload: response.data });
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "CREATE_BOARD_FAIL" });
        if (error.response.status === 401) {
          dispatch({ type: "HIDE_ADD_BOARD" });
        }
        return error;
      });
  };
}

export function getBoardList(board = "") {
  return (dispatch) => {
    dispatch({ type: "SET_STATE", payload: { reloadList: false } });
    dispatch({ type: "GET_BOARD_START" });
    return requestAPI("board", {
      method: "get",
      data: { board },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "GET_BOARD_SUCCESS", payload: response.data });
          dispatch({ type: "SET_STATE", payload: { reloadList: false } });
        } else {
          dispatch({ type: "GET_BOARD_FAIL", payload: response.data });
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "GET_BOARD_FAIL" });
        return error;
      });
  };
}

export function getBoardTypes(board = "") {
  return (dispatch) => {
    dispatch({ type: "GET_BOARD_TYPES_START" });
    return requestAPI("board/types", {
      method: "get",
      data: { board },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "GET_BOARD_TYPES_SUCCESS", payload: response.data });
        } else {
          dispatch({ type: "GET_BOARD_TYPES_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "GET_BOARD_TYPES_FAIL" });
      });
  };
}

export function searchCard(searchQuery, boardId) {
  return (dispatch) => {
    dispatch({ type: "SEARCH_CARD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "card/search",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        board: boardId,
        query: searchQuery,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({
            type: "SEARCH_CARD_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "SEARCH_CARD_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "SEARCH_CARD_FAIL" });
      });
  };
}

export function getFilterCard(filterArr, boardId) {
  const query = [];
  for (const index in filterArr) {
    const member = JSON.parse(index);
    query.push({
      email: member.email.toLowerCase(),
      filter: filterArr[index],
      firstName: member.firstName,
      lastName: member.lastName,
    });
  }
  return (dispatch) => {
    dispatch({ type: "FILTER_CARD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "card/filter",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        board: boardId,
        query: query,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({
            type: "FILTER_CARD_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "FILTER_CARD_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "FILTER_CARD_FAIL" });
      });
  };
}

export function deleteBoard(board) {
  return (dispatch) => {
    dispatch({ type: "DELETE_BOARD_START" });
    return axios({
      method: "delete",
      url: `${BASE_URL}board/${board._id}`,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "DELETE_BOARD_SUCCESS", payload: response.data });
          dispatch({ type: "SHOW_DELETE_NOTIFICATION", board });
        } else {
          dispatch({ type: "DELETE_BOARD_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "DELETE_BOARD_FAIL" });
      });
  };
}
export function deleteColumn(boardList, board, column) {
  return (dispatch) => {
    dispatch({ type: "DELETE_COLUMN_START" });
    return axios({
      method: "delete",
      url: BASE_URL + "column/" + column._id,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "DELETE_COLUMN_SUCCESS",
            payload: response.data,
            boardList,
            board: response.data.data,
            column,
            lists,
          });
        } else {
          dispatch({ type: "DELETE_COLUMN_FAIL", payload: response.data });
        }
        const payload = { item: [column._id], type: "column" };
        dispatch({
          type: "DELETED_ITEMS_SUCCESS",
          payload,
        });
      })
      .catch(() => {
        dispatch({ type: "DELETE_COLUMN_FAIL" });
      });
  };
}
export function deleteAllCards(column) {
  return (dispatch) => {
    dispatch({ type: "DELETE_COLUMN_START" });
    return axios({
      method: "delete",
      url: BASE_URL + "column/delete-cards/" + column._id,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "DELETE_ALL_CARDS_SUCCESS", data: column });
          const payload = {
            item: column._id,
            data: response.data.data,
            type: "allCards",
          };
          dispatch({
            type: "DELETED_ITEMS_SUCCESS",
            payload,
          });
        }
      })
      .catch(() => {});
  };
}
export function deleteCard(card) {
  return (dispatch) => {
    dispatch({ type: "DELETE_CARD_START" });
    return axios({
      method: "delete",
      url: BASE_URL + "card/" + card._id,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "DELETE_CARD_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "DELETE_CARD_FAIL", payload: response.data });
        }
        const payload = { item: [card._id], type: "card" };
        dispatch({
          type: "DELETED_ITEMS_SUCCESS",
          payload,
        });
      })
      .catch(() => {
        dispatch({ type: "DELETE_CARD_FAIL" });
      });
  };
}
export function removeAttachment(card, attachment, comment) {
  return (dispatch) => {
    dispatch({ type: "DELETE_ATTACHMENT_START" });
    return axios({
      method: "delete",
      url: comment
        ? BASE_URL +
          "card/" +
          card._id +
          "/comment/" +
          comment +
          "/attachment/" +
          attachment._id
        : BASE_URL + "card/" + card._id + "/attachment/" + attachment._id,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "DELETE_ATTACHMENT_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "DELETE_ATTACHMENT_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "DELETE_COLUMN_FAIL" });
      });
  };
}
export function undoDeleteBoard(id) {
  return (dispatch) => {
    dispatch({ type: "UNDO_DELETE_BOARD_START" });
    return axios({
      method: "get",
      url: BASE_URL + "board/undo-delete/" + id,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({
            type: "UNDO_DELETE_BOARD_SUCCESS",
            payload: response.data,
          });
        } else {
          dispatch({ type: "UNDO_DELETE_BOARD_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "UNDO_DELETE_BOARD_FAIL" });
      });
  };
}
export function editBoardName(board, boardList, name) {
  return (dispatch) => {
    dispatch({ type: "EDIT_BOARD_NAME_START" });
    return axios({
      method: "patch",
      url: BASE_URL + "board",
      data: {
        name: name,
        id: board._id,
      },
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          boardList.forEach((element, index) => {
            if (response.data.data._id === boardList[index]._id) {
              boardList[index] = response.data.data;
            }
          });
          lists = response.data.data.columns;
          dispatch({
            type: "EDIT_BOARD_NAME_SUCCESS",
            payload: response.data,
            boardList,
            lists,
          });
        } else {
          dispatch({ type: "EDIT_BOARD_NAME_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "EDIT_BOARD_NAME_FAIL" });
      });
  };
}
export function editColumnName(name, column, board, boardList, confetti) {
  return (dispatch) => {
    dispatch({ type: "EDIT_COLUMN_NAME_START" });
    return axios({
      method: "put",
      url: BASE_URL + "column",
      data: {
        name: name,
        confetti: confetti,
        column: column._id,
      },
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "EDIT_COLUMN_NAME_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "EDIT_COLUMN_NAME_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "EDIT_COLUMN_NAME_FAIL" });
      });
  };
}

export function editConfetti(confetti, column) {
  return (dispatch) => {
    dispatch({ type: "EDIT_COLUMN_NAME_START" });
    return axios({
      method: "put",
      url: BASE_URL + "column/confetti",
      data: {
        confetti: confetti,
        column: column._id,
      },
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "EDIT_COLUMN_NAME_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "EDIT_COLUMN_NAME_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "EDIT_COLUMN_NAME_FAIL" });
      });
  };
}
export function editCardDetail(card, data) {
  return (dispatch) => {
    dispatch({ type: "EDIT_CARD_NAME_START" });
    return axios({
      method: "put",
      url: BASE_URL + "card",
      data: {
        card: data,
        id: card._id,
      },
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "EDIT_CARD_NAME_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "EDIT_CARD_NAME_FAIL", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "EDIT_COLUMN_NAME_FAIL" });
        if (error.response.status === 401) {
          localStorage.setItem("authorization", null);
          dispatch({ type: "USER_LOGGED_OUT" });
        }
      });
  };
}
export function getBoardHistory(board) {
  return (dispatch) => {
    dispatch({ type: "GET_BOARD_HISTORY_START" });
    return axios({
      method: "post",
      url: BASE_URL + "board/history",
      data: {
        board,
      },
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({
            type: "GET_BOARD_HISTORY_SUCCESS",
            payload: response.data,
          });
        } else {
          dispatch({ type: "GET_BOARD_HISTORY_FAIL", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_BOARD_HISTORY_FAIL" });
        if (error.response.status === 401) {
          localStorage.setItem("authorization", null);
          dispatch({ type: "USER_LOGGED_OUT" });
        }
      });
  };
}

export function getBoardDetail(board, boardList, labels = []) {
  let labelAssigned = labels.map((lbl) => lbl._id);
  labelAssigned = labelAssigned.join(",");
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_BOARD_DETAIL_START" });
    return axios({
      method: "get",
      url: `${BASE_URL}board/get-board/${board._id}/${labelAssigned}`,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          boardList.forEach((element, index) => {
            if (response.data.data._id === boardList[index]._id) {
              boardList[index] = response.data.data;
              lists = response.data.data.columns;
            }
          });
          const { user } = getState();
          const currentMember =
            response.data.data.members.find(
              (member) => member.email.email == user.loggedInUser?.email
            ) || {};
          dispatch({
            type: "SHOW_BOARD_DETAIL_SUCCESS",
            payload: response.data,
            boardList,
            lists,
            currentMember,
            labelAssignedId: !labelAssigned ? [] : labelAssigned.split(","),
          });
        } else {
          if (response.data.status === "error") {
            NotificationManager.error(response.data.message);
          }
          dispatch({ type: "SHOW_BOARD_DETAIL_FAIL", payload: response.data });
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "SHOW_BOARD_DETAIL_FAIL" });
        return error;
      });
  };
}
export function registerSocketListeners(socket, user) {
  return (dispatch) => {
    user &&
      user.email &&
      socket.emit("join", { email: user.email.toLowerCase() });
    socket.on("change:board", (data) => {
      dispatch({ type: "CHANGE_BOARD", payload: data });
    });
    socket.on("delete:board", (data) => {
      dispatch({ type: "NOTIFY_DELETE_BOARD_SUCCESS", payload: data });
    });
    socket.on("notification:desktop", (data) => {
      if (window.Notification && Notification) {
        new Notification(data.title, {
          icon: "assets/images/robot.png",
          body: data.body,
        });
      }
    });
  };
}

export function toggleShowTitleOnly(action, boardId) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_BOARD" });
    return axios({
      method: "post",
      url: BASE_URL + "board/toggle-show-title-only",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        action,
        board_id: boardId,
        // board: boardId, //  TODO :-  boardId Use Multiple Time.
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "TOGGLE_SHOW_TITLE_ONLY", payload: action });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.setItem("authorization", null);
          dispatch({ type: "USER_LOGGED_OUT" });
        }
      });
  };
}

export function moveCardToTopOrBottom(cards, column, board) {
  return (dispatch) => {
    dispatch({ type: "MOVE_TO_TOP_BOTTOM" });
    return axios({
      method: "post",
      url: BASE_URL + "card/move-card",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        cards,
        column,
        board,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          const lists = response.data.data.columns;
          dispatch({ type: MOVE_CARD, board: response.data.data, lists });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({ type: "HIDE_ADD_BOARD" });
        }
      });
  };
}

export function addCheckList(card) {
  return (dispatch) => {
    dispatch({ type: "ADD_CHECKLIST" });
    return axios({
      method: "post",
      url: BASE_URL + "checkList",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        card,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          const lists = response.data.data.columns;
          dispatch({
            type: "ADD_CHECKLIST_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
          dispatch({
            type: cardsActions.SET_STATE,
            payload: {
              checkListAdded: true,
            },
          });
        } else {
          dispatch({ type: "ADD_CHECKLIST_FAIL", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "ADD_CHECKLIST_FAIL", payload: error.data });
      });
  };
}

export function updateCheckList(checkList, card) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_CHECKLIST" });
    return axios({
      method: "put",
      url: BASE_URL + "checkList",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        checkList,
        card,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          const lists = response.data.data.columns;
          dispatch({
            type: "UPDATE_CHECKLIST_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "UPDATE_CHECKLIST_FAIL", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_CHECKLIST_FAIL", payload: error.data });
      });
  };
}

export function deleteCheckList(checkList, card, type) {
  return (dispatch) => {
    dispatch({ type: "DELETE_CHECKLIST" });
    return axios({
      method: "delete",
      url: BASE_URL + "checkList",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        checkList,
        card,
        type,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          const lists = response.data.data.columns;
          dispatch({
            type: "DELETE_CHECKLIST_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "DELETE_CHECKLIST_FAIL", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "DELETE_CHECKLIST_FAIL", payload: error.data });
      });
  };
}

export function getLabelByName(searchQuery, board) {
  return (dispatch) => {
    dispatch({ type: "SEARCH_LABEL_START" });
    return axios({
      method: "post",
      url: BASE_URL + "label/search",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        query: searchQuery.trim(),
        board,
      },
    })
      .then((response) => {
        const labels = response.data.data ? response.data.data.labels : [];
        dispatch({
          type: "SEARCH_LABEL_SUCCESS",
          payload: labels,
        });
        return response;
      })
      .catch((error) => {
        dispatch({ type: "GET_BOARD_START" });
        return error;
      });
  };
}

export function createNewLabel(data) {
  return (dispatch) => {
    dispatch({ type: "CREATE_NEW_LABEL" });
    return axios({
      method: "post",
      url: BASE_URL + "label",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: data,
    })
      .then((response) => {
        const labelData = response.data ? response.data.data : [];
        dispatch({
          type: "LABEL_CREATE_SUCCESS",
          payload: labelData,
        });
      })
      .catch(() => {
        dispatch({ type: "HIDE_LOADER" });
      });
  };
}

export function updateAssignee(
  card,
  assignTo = "",
  date = "",
  labelSelected = "",
  showCard = true
) {
  return (dispatch) => {
    dispatch({ type: "ADD_TASK_START" });
    return axios({
      method: "put",
      url: BASE_URL + "task",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        card,
        date,
        assignTo,
        labelSelected,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          let updatedCard = {};
          lists.forEach((list) => {
            if (list._id == card.column) {
              list.cards.forEach((crd) => {
                if (crd._id == card._id) {
                  updatedCard = crd;
                }
              });
            }
          });
          if (updatedCard._id && showCard) {
            dispatch({ type: "SHOW_CARD_DETAIL", payload: updatedCard });
          }
          dispatch({
            type: "ADD_TASK_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "ADD_TASK_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_TASK_FAIL" });
      });
  };
}

export function updateLabel(
  labels,
  board,
  card,
  selectedCard = {},
  showCard = true
) {
  return (dispatch) => {
    dispatch({ type: "CREATE_NEW_LABEL" });
    return axios({
      method: "put",
      url: BASE_URL + "label",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        labels,
        card,
        board,
      },
    })
      .then((response) => {
        const { data = {} } = response;
        const labelData = data.data ? data.data : [];
        const { removedLabels = [], labels = [] } = labelData;
        if (labels.length > 0 && showCard) {
          selectedCard["labelAssigned"] = labels;
          dispatch({
            type: "SHOW_CARD_DETAIL",
            payload: selectedCard,
          });
        }
        const payload = { item: removedLabels, type: "label" };
        dispatch({
          type: "LABEL_CREATE_SUCCESS",
          payload: labelData,
        });
        dispatch({
          type: "DELETED_ITEMS_SUCCESS",
          payload,
        });
      })
      .catch(() => {
        dispatch({ type: "HIDE_LOADER" });
      });
  };
}

export function undoDelete(labels, board) {
  return (dispatch) => {
    dispatch({ type: "CREATE_NEW_LABEL" });
    return axios({
      method: "post",
      url: BASE_URL + "label/undo-delete",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        labels,
        board,
      },
    })
      .then((response) => {
        const { data = {} } = response;
        const labelData = data.data ? data.data : [];
        const { removedLabels = [] } = labelData;
        const payload = { item: removedLabels, type: "label" };
        dispatch({
          type: "LABEL_CREATE_SUCCESS",
          payload: labelData,
        });
        dispatch({
          type: "DELETED_ITEMS_SUCCESS",
          payload,
        });
      })
      .catch(() => {
        dispatch({ type: "HIDE_LOADER" });
      });
  };
}

export function undoDeleteCard(cards, board) {
  return (dispatch) => {
    dispatch({ type: "DELETE_CARD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "card/undo-delete",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        cards,
        board,
      },
    })
      .then((response) => {
        const { data = {} } = response;
        const cardData = data.data ? data.data : [];
        const { removedCards = [] } = cardData;
        lists = cardData.columns;
        const payload = { item: removedCards, type: "card" };
        dispatch({
          type: "DELETED_ITEMS_SUCCESS",
          payload,
        });
        dispatch({
          type: "DELETE_CARD_SUCCESS",
          payload: data,
          board: cardData,
          lists,
        });
      })
      .catch(() => {
        dispatch({ type: "DELETE_CARD_FAIL" });
      });
  };
}

export function undoDeleteColumn(column, board) {
  return (dispatch) => {
    dispatch({ type: "DELETE_CARD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "column/undo-delete",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        column,
        board,
      },
    })
      .then((response) => {
        const { data = {} } = response;
        const columnData = data.data ? data.data : [];
        const { removedColumns = [] } = columnData;
        lists = columnData.columns;
        const payload = { item: removedColumns, type: "card" };
        dispatch({
          type: "DELETED_ITEMS_SUCCESS",
          payload,
        });
        dispatch({
          type: "DELETE_CARD_SUCCESS",
          payload: data,
          board: columnData,
          lists,
        });
      })
      .catch(() => {
        dispatch({ type: "DELETE_CARD_FAIL" });
      });
  };
}

export function undoDeleteAllCards(column, cards, board) {
  return (dispatch) => {
    dispatch({ type: "DELETE_CARD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "column/undo-delete-cards",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        column,
        cards,
        board,
      },
    })
      .then((response) => {
        const { data = {} } = response;
        const columnData = data.data ? data.data : [];
        lists = columnData.columns;
        const payload = { item: [], data: [], type: "allCards" };
        dispatch({
          type: "DELETED_ITEMS_SUCCESS",
          payload,
        });
        dispatch({
          type: "DELETE_CARD_SUCCESS",
          payload: data,
          board: columnData,
          lists,
        });
      })
      .catch(() => {
        dispatch({ type: "DELETE_CARD_FAIL" });
      });
  };
}

export function doneTheTask(tasks, card, board) {
  return (dispatch) => {
    dispatch({ type: "ADD_TASK_START" });
    return axios({
      method: "post",
      url: BASE_URL + "task/done",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        card,
        tasks,
        board,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          let updatedCard = {};
          lists.forEach((list) => {
            if (list._id == card.column) {
              list.cards.forEach((crd) => {
                if (crd._id == card._id) {
                  updatedCard = crd;
                }
              });
            }
          });
          if (updatedCard._id) {
            dispatch({ type: "SHOW_CARD_DETAIL", payload: updatedCard });
          }
          dispatch({
            type: "ADD_TASK_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "ADD_TASK_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_TASK_FAIL" });
      });
  };
}

export function activateTask(tasks, card, board) {
  return (dispatch) => {
    dispatch({ type: "ADD_TASK_START" });
    return axios({
      method: "post",
      url: BASE_URL + "task/activate",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: {
        card,
        tasks,
        board,
      },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          let updatedCard = {};
          lists.forEach((list) => {
            if (list._id == card.column) {
              list.cards.forEach((crd) => {
                if (crd._id == card._id) {
                  updatedCard = crd;
                }
              });
            }
          });
          if (updatedCard._id) {
            dispatch({ type: "SHOW_CARD_DETAIL", payload: updatedCard });
          }
          dispatch({
            type: "ADD_TASK_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "ADD_TASK_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_TASK_FAIL" });
      });
  };
}

export function deleteContent(cardId, attachmentId) {
  return (dispatch) => {
    dispatch({ type: "DELETE_ATTACHMENT_START" });
    return axios({
      method: "delete",
      url: BASE_URL + "card/content",
      data: {
        cardId,
        attachmentId,
      },
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "DELETE_ATTACHMENT_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "DELETE_ATTACHMENT_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "DELETE_COLUMN_FAIL" });
      });
  };
}

export function addVideoUrl(videoUrl, type, cardId) {
  return (dispatch) => {
    dispatch({ type: "ADD_CARD_ATTACHMENT_START" });
    return axios({
      method: "post",
      url: BASE_URL + "card/add-url",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: { urlUploaded: videoUrl, type, cardId },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "ADD_CARD_ATTACHMENT_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({
            type: "ADD_CARD_ATTACHMENT_FAIL",
            payload: response.data,
          });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_CARD_ATTACHMENT_FAIL" });
      });
  };
}

export function deleteAttachments(cardId) {
  return (dispatch) => {
    dispatch({ type: "DELETE_ATTACHMENT_START" });
    return axios({
      method: "delete",
      url: `${BASE_URL}card/attachments/${cardId}`,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "DELETE_NOTE_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        } else {
          dispatch({ type: "DELETE_NOTE_FAIL", payload: response.data });
        }
      })
      .catch(() => {
        dispatch({ type: "EDIT_NOTE_FAIL" });
      });
  };
}

export function getBoardDetails(board, boardList, labels = []) {
  let labelAssigned = labels.map((lbl) => lbl._id);
  labelAssigned = labelAssigned.join(",");
  return (dispatch) => {
    dispatch({ type: "SHOW_BOARD_DETAIL_START" });
    return axios({
      method: "get",
      url: `${BASE_URL}board/get/${board._id}/${labelAssigned}`,
      headers: { Authorization: localStorage.getItem("authorization") },
    })
      .then((response) => {
        dispatch({ type: "SHOW_BOARD_DETAIL_FAIL" });
        return response;
      })
      .catch((error) => {
        dispatch({ type: "SHOW_BOARD_DETAIL_FAIL" });
        return error;
      });
  };
}

export function duplicateCard(
  cardId,
  columnId,
  boardId,
  isCopy = false,
  activeBoard
) {
  return (dispatch) => {
    dispatch({ type: "ADD_CARD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "card/move-or-copy",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: { cardId, columnId, boardId, isCopy, activeBoard },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "ADD_CARD_SUCCESS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        }
      })
      .catch(() => {
        dispatch({ type: "ADD_CARD_FAIL" });
      });
  };
}

export function updateBoardSettings(
  boardId,
  boardTitle,
  isPublic = false,
  isCopying = false
) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_BOARD_START" });
    return axios({
      method: "post",
      url: BASE_URL + "board/board-settings",
      headers: { Authorization: localStorage.getItem("authorization") },
      data: { isPublic, isCopying, boardTitle, boardId },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          lists = response.data.data.columns;
          dispatch({
            type: "BOARD_SETTINGS",
            payload: response.data,
            board: response.data.data,
            lists,
          });
        }
      })
      .catch(() => {
        dispatch({ type: "UPDATE_BOARD_FAIL" });
      });
  };
}

export function isValidBoard(board = "", boardList = []) {
  return (dispatch) => {
    return requestAPI("board/get-board", {
      method: "get",
      data: { board },
    })
      .then((resp) => {
        const boardId = get(resp, "data.data._id", "");
        const position = boardList.findIndex((brd) => brd === board);
        const payload = !!(boardId && position === -1);
        dispatch({ type: "ENABLED_BOARD_PUBLIC", payload });
        return resp;
      })
      .catch((error) => {
        dispatch({ type: "ENABLED_BOARD_PUBLIC", payload: "" });
        return error;
      });
  };
}

export function updateBoardList(boards = []) {
  return (dispatch) => {
    dispatch({ type: "SET_STATE", payload: { reloadList: false } });
    return requestAPI("board", {
      method: "put",
      data: { boards: JSON.stringify(boards) },
    })
      .then((response) => {
        if (response.data.status !== "error") {
          dispatch({ type: "GET_BOARD_SUCCESS", payload: response.data });
          dispatch({ type: "SET_STATE", payload: { reloadList: false } });
        } else {
          dispatch({ type: "GET_BOARD_FAIL", payload: response.data });
        }
        return response;
      })
      .catch((error) => {
        dispatch({ type: "GET_BOARD_FAIL" });
        return error;
      });
  };
}
